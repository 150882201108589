import {
  getActiveMhaStatus,
  MhaDashboardDataParams,
  MhaDashboardDataResponse,
  MhaStatus,
  MHAStatusType,
  PatientStateTableRow,
} from "@aspire/common";
import { Warning } from "@mui/icons-material";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import { debounce } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Banner,
  BannerList,
  Button,
  Dropdown,
  LoadingSpinner,
  TextField,
} from "~/components/design-system/index.js";
import { formatMhaStatus } from "~/components/util.js";
import { routeFns } from "~/routes.js";
import "../../@types/index.js";

export type MhaStatusDashboardProps = {
  data?: MhaDashboardDataResponse;
  isLoading: boolean;
  error?: string;
  showNhsNumber: boolean;
};

type ExpiryWarning = {
  showTime: boolean;
  warningThreshold: { unit: ManipulateType; value: number };
  criticalThreshold: { unit: ManipulateType; value: number };
};

const expiryThresholdTimes: { [key in MhaStatus]?: ExpiryWarning } = {
  [MhaStatus.Section17A]: {
    warningThreshold: { unit: "weeks", value: 8 },
    criticalThreshold: { unit: "weeks", value: 2 },
    showTime: false,
  },
  [MhaStatus.Section3]: {
    warningThreshold: { unit: "weeks", value: 8 },
    criticalThreshold: { unit: "weeks", value: 1 },
    showTime: false,
  },
  [MhaStatus.Section2]: {
    warningThreshold: { unit: "days", value: 7 },
    criticalThreshold: { unit: "days", value: 3 },
    showTime: false,
  },
  [MhaStatus.Section4]: {
    warningThreshold: { unit: "hours", value: 48 },
    criticalThreshold: { unit: "days", value: 1 },
    showTime: true,
  },
  [MhaStatus.Section5_2]: {
    warningThreshold: { unit: "hours", value: 48 },
    criticalThreshold: { unit: "days", value: 1 },
    showTime: true,
  },
  [MhaStatus.Section5_4]: {
    warningThreshold: { unit: "hours", value: 3 },
    criticalThreshold: { unit: "hours", value: 1 },
    showTime: true,
  },
};

function expiryCellContents(
  mhaStatus: MhaStatus,
  activeStateType: MHAStatusType | undefined,
  expiryDateTime?: string,
) {
  if (mhaStatus === MhaStatus.Incomplete)
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning sx={{ color: "orange", marginRight: "0.2em" }} />
        Unknown
      </Box>
    );

  if (mhaStatus === MhaStatus.Expired)
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning sx={{ color: "red", marginRight: "0.2em" }} />
        Unknown
      </Box>
    );

  if (!expiryDateTime) return "Unknown";

  const expiryThreshold = expiryThresholdTimes[mhaStatus];

  if (!expiryThreshold) return "Unknown";

  const expiryDate = dayjs(expiryDateTime);
  const now = dayjs();

  const formatFn = (d: Dayjs) =>
    expiryThreshold.showTime
      ? d.format("DD MMM YYYY HH:mm")
      : d.format("DD MMM YYYY");

  if (expiryDate.isBefore(now)) return `Expired (${formatFn(expiryDate)})`;

  const formattedDate = formatFn(expiryDate);

  const expiryDateComponent = expiryThreshold.showTime ? (
    formattedDate
  ) : (
    <Tooltip title="Expiry time is end of day">
      <span>{formattedDate}</span>
    </Tooltip>
  );

  if (
    activeStateType === "pending-renewal" ||
    activeStateType === "pending-cto"
  ) {
    return expiryDateComponent;
  }

  if (
    expiryDate.isBefore(
      now.add(
        expiryThreshold.criticalThreshold.value,
        expiryThreshold.criticalThreshold.unit,
      ),
    )
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning sx={{ color: "red", marginRight: "0.2em" }} />
        {expiryDateComponent}
      </Box>
    );
  }

  if (
    expiryDate.isBefore(
      now.add(
        expiryThreshold.warningThreshold.value,
        expiryThreshold.warningThreshold.unit,
      ),
    )
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Warning sx={{ color: "orange", marginRight: "0.2em" }} />
        {expiryDateComponent}
      </Box>
    );
  }

  return expiryDateComponent;
}

export function MhaStatusDashboardRow({
  index,
  row,
  showNhsNumber,
}: {
  row: PatientStateTableRow;
  index: number;
  showNhsNumber: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const activeState = getActiveMhaStatus(
    row.state.state,
    dayjs().toISOString(),
  );

  return (
    <TableRow
      sx={{
        backgroundColor:
          index % 2 === 0 ? theme.palette.common.paleBlue : "white",
      }}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          <Link
            style={{
              textDecoration: "none",
            }}
            to={routeFns.patientHome(row.state.patientId)}
          >
            <Typography
              sx={{
                textDecoration: "none",
                fontSize: "0.9375rem",
                backgroundColor: "transparent",
                outline: "none",
                color: "blue",
              }}
              variant="subtitle1"
            >
              {`${row.patientInfo.name.family.toUpperCase()}, ${row.patientInfo.name.given}`}
            </Typography>
          </Link>
        </Box>
      </TableCell>
      {showNhsNumber && (
        <TableCell>
          {row.patientInfo.nhsNumber ?? t("common.unknown")}
        </TableCell>
      )}
      <TableCell>
        {row.patientInfo.dateOfBirth
          ? dayjs(row.patientInfo.dateOfBirth).format("DD MMM YYYY")
          : "Unknown"}
      </TableCell>
      <TableCell>
        {formatMhaStatus(row.state.state.mhaStatus.status, activeState?.type)}
      </TableCell>
      <TableCell>
        {expiryCellContents(
          row.state.state.mhaStatus.status,
          activeState?.type,
          row.state.state.mhaStatus.expiryDateTime,
        )}
      </TableCell>
      <TableCell>{row.state.state.hospital?.name ?? "-"}</TableCell>
      <TableCell>
        <a href={routeFns.patientHome(row.state.patientId)}>
          <Button label="View" />
        </a>
      </TableCell>
    </TableRow>
  );
}

export function MhaStatusSearchBarControls(props: {
  params: Omit<MhaDashboardDataParams, "limit" | "offset">;
  patientSearchFilter: string;
  setPatientSearchFilter: (value: string) => void;
  updateParams: (
    params: Omit<MhaDashboardDataParams, "limit" | "offset">,
  ) => void;
  title: string;
  subTitle: React.ReactNode;
}) {
  const patientSearchFilter = props.patientSearchFilter;
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [organisationName, setOrganisationName] = useState(
    props.params.organisationName,
  );

  const updateFn = useCallback(debounce(props.updateParams, 500), []);

  useEffect(() => {
    updateFn({ ...props.params, patientSearchFilter, organisationName });
  }, [JSON.stringify(props.params), patientSearchFilter, organisationName]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "1em",
          marginTop: "1em",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "0.75rem",
            color: theme.palette.common.blueGray,
          }}
        >
          {props.title}
        </Typography>
        <Typography
          sx={{ fontSize: "0.75rem", color: theme.palette.common.blueGray }}
        >
          {props.subTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: "flex-start",
            flex: 1,
          }}
        >
          <Box
            sx={{
              minWidth: isSmallScreen ? "100%" : "250px",
              marginBottom: isSmallScreen ? "1em" : 0,
              marginRight: isSmallScreen ? 0 : "1em",
            }}
          >
            <TextField
              useFullWidth
              name="patientSearchFilter"
              label="Filter by patient"
              value={patientSearchFilter}
              onChange={props.setPatientSearchFilter}
            />
          </Box>
          <Box
            sx={{
              minWidth: isSmallScreen ? "100%" : "250px",
              marginBottom: isSmallScreen ? "1em" : 0,
              mr: 2,
            }}
          >
            <TextField
              useFullWidth
              name="organisationName"
              label="Filter by hospital"
              value={organisationName}
              onChange={setOrganisationName}
            />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          <Box
            sx={{
              marginRight: isSmallScreen ? 0 : "1em",
              marginBottom: isSmallScreen ? "1em" : 0,
              minWidth: "300px",
            }}
          >
            <Dropdown
              label="Detention Status"
              name="statuses"
              renderedValue={`${props.params.statuses.length} statuses selected`}
              multiple
              selectedValue={props.params.statuses}
              values={[
                { value: MhaStatus.Incomplete, label: "Unknown (Incomplete)" },
                { value: MhaStatus.Expired, label: "Unknown (Expired)" },
                { value: MhaStatus.Section5_2, label: "Section 5(2)" },
                { value: MhaStatus.Section5_4, label: "Section 5(4)" },
                { value: MhaStatus.Section2, label: "Section 2" },
                { value: MhaStatus.Section3, label: "Section 3" },
                { value: MhaStatus.Section4, label: "Section 4" },
                { value: MhaStatus.Section17A, label: "Section 17(A)" },
                { value: MhaStatus.NotDetained, label: "Not Detained" },
                { value: MhaStatus.Unknown, label: "Unknown" },
              ]}
              onChange={(newValues) => {
                // Don't allow us to end up with an empty list
                if (newValues.length > 0)
                  props.updateParams({ ...props.params, statuses: newValues });
              }}
            />
          </Box>
          <Box sx={{ minWidth: "300px" }}>
            <Dropdown
              label="Expiry Date"
              name="expiry"
              renderedValue={`${props.params.expiryLessThan ?? "-"}`}
              selectedValue={props.params.expiryLessThan}
              placeholder="Select a date range.."
              values={[
                { value: "", label: "-" },
                { value: "24 hours", label: "Expires in < 24 hours" },
                { value: "7 days", label: "Expires in < 7 days" },
                { value: "30 days", label: "Expires in < 30 days" },
                { value: "2 months", label: "Expires in < 2 months" },
              ]}
              onChange={(newValue) =>
                props.updateParams({
                  ...props.params,
                  expiryLessThan: newValue,
                })
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export function MhaStatusDashboard(props: MhaStatusDashboardProps) {
  if (props.isLoading)
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "4em",
        }}
      >
        <LoadingSpinner />
      </Box>
    );

  // TODO: work this out
  if (props.error)
    return <Banner bannerType={BannerList.ERROR} title={props.error} />;

  // TODO: work this out
  if (!props.data?.rows.length)
    return <Banner bannerType={BannerList.INFO} title="No results found" />;

  const headerCellSx = { fontWeight: "bold" };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell sx={headerCellSx}>Patient Name</TableCell>
            {props.showNhsNumber && (
              <TableCell sx={headerCellSx}>NHS No.</TableCell>
            )}
            <TableCell sx={headerCellSx}>Born</TableCell>
            <TableCell sx={headerCellSx}>MHA Status</TableCell>
            <TableCell sx={headerCellSx}>Expiry Date / Time</TableCell>
            <TableCell sx={headerCellSx}>Hospital</TableCell>
            <TableCell sx={headerCellSx}></TableCell>
          </TableHead>
          <TableBody>
            {props.data.rows.map((row, index) => (
              <MhaStatusDashboardRow
                row={row}
                index={index}
                showNhsNumber={props.showNhsNumber}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
