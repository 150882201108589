import { isGuestUserSession, Patient } from "@aspire/common";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { LoggedInUserContext } from "../../../Contexts.js";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection.js";
import { useStickyHeader } from "../../../hooks/StickyHeader/useStickyHeader.js";
import { routeFns } from "../../../routes.js";
import { MenuFlyout } from "../../design-system/MenuFlyout.js";
import { HorizontalLine } from "../../form/HorizontalLine.js";
import { VerticalLine } from "../../form/VerticalLine.js";
import { PatientCrisisInfo } from "../PatientCrisisInfo.js";
import { StickyContainer } from "../styleWrappers.js";
import "./PatientBannerTransition.css";

import {
  ExternalPatientLinkEnhanced as BaseExternalPatientLink,
  CreateExternalPatientDemograhicsPullEventRequest,
  getActiveMhaStatus,
  MhaStatus,
  PatientStateWithContext,
  PatientTimelineResponse,
} from "@aspire/common";
import { v4 } from "uuid";
import { useMhaStatus } from "~/hooks/apiCalls.js";
import { userCanEditMhaStatus } from "~/pages/EditMhaStatus/EditMhaStatusPage.js";
import { PatientMergeDialog } from "../../../pages/PatientIndex/PatientMerge/PatientMergeDialog.js";
import { PatientUndoMergeDialog } from "../../../pages/PatientIndex/PatientMerge/PatientUndoMergeDialog.js";
import { ManualPullButton } from "../../design-system/ExternalPatientLink/ManualPullButton/ManualPullButton.js";
import {
  infoBoxMhaStatusTitle,
  InfoBox as MhaStatusInfoBox,
  PatientStateDisplay,
} from "./PatientStateDisplay.js";

type ExternalPatientLink = Pick<
  BaseExternalPatientLink,
  | "id"
  | "patientId"
  | "externalSystemType"
  | "externalSystemId"
  | "externalSystemDisplayName"
  | "externalPatientIdType"
  | "externalPatientIdValue"
  | "dateLastProcessed"
  | "priorityWithinExternalSystem"
  | "canPull"
  | "canPush"
>;

export interface PatientBannerProps {
  patient: Patient;

  patientTimeline?: PatientTimelineResponse;
  reloadPatientTimeline?: () => void;
  nhsNumber?: string;

  externalPatientLinks?: {
    links: ExternalPatientLink[];
    onSync: (
      externalPatientLink: BaseExternalPatientLink,
      reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
    ) => Promise<void>;
  };

  disableSticky?: boolean;
  isHorizontalLineHidden?: boolean;
  isHidden?: boolean;
  showUnknownMhaStatus?: boolean;
}

type MainTextStylesObject = {
  color: string;
  fontSize: string;
};

type MainTextStylesFunction = (theme: Theme) => MainTextStylesObject;

type MainTextStyles = MainTextStylesObject | MainTextStylesFunction;

type BannerContainerProps = {
  issticky?: string;
  ismobilemode?: string;
  theme?: Theme;
};

const BannerContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSticky",
})<BannerContainerProps>(({ issticky, ismobilemode, theme }) => ({
  flexDirection: "column",
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(0.8),
  padding: issticky && ismobilemode ? theme.spacing(1.5) : theme.spacing(2.5),
}));

const getSmallTextStyles = (theme: Theme) => ({
  color: "primary.hint",
  fontSize: theme.spacing(1.375),
});

const compactTextStyles = (theme: Theme) => ({
  color: "text.primary",
  fontSize: theme.spacing(1.625),
});

const shouldShowNhsLabel = (
  nhsNumberEnabled: boolean,
  nhsNumber: string | undefined,
) => {
  return !!(nhsNumberEnabled || (!nhsNumberEnabled && nhsNumber));
};

function Spacer() {
  const theme = useTheme();
  return (
    <Box sx={{ marginLeft: "0.7em", marginRight: "0.7em", height: "100%" }}>
      <VerticalLine color={theme.palette.text.disabled} height="100%" />
    </Box>
  );
}

export function PatientBanner(props: PatientBannerProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user;
  const isEditable = !isGuestUserSession(user);
  const patientMhaStatusEnabled =
    user?.sessionOrganisationConfiguration?.patientStateEnabled ?? false;
  const nhsNumberEnabled =
    user?.sessionOrganisationConfiguration?.nhsNumberEnabled ?? false;

  const showEditMhaStatus = userCanEditMhaStatus(user);
  const { mhaStatus } = useMhaStatus({
    patientId: props.patient.id,
  });

  const { isSticky } = useStickyHeader();
  const { isMobileView } = useScreenDetection();
  const [isMainMenuOpen, setIsMainMenuOpen] = React.useState(false);
  const location = useLocation();
  const [showPatientMergeDialog, setShowPatientMergeDialog] =
    React.useState(false);

  const [showPatientUndoMergeDialog, setShowPatientUndoMergeDialog] =
    React.useState(false);

  const mainTextStyles = {
    color: "text.primary",
    fontSize: isMobileView ? theme.spacing(1.5) : theme.spacing(2),
  };

  const patientAge = dayjs().diff(dayjs(props.patient.dateOfBirth), "year");

  const isNotSticky = props.disableSticky || !isSticky;

  const showExtendedContent =
    props.disableSticky || (!isSticky && !isMobileView);

  const cardRef = React.useRef(null);

  const menuFlyoutOptions = [];

  const disableEditMHAStatusBtn =
    location.pathname === routeFns.editMhaStatusPage(props.patient.id) ||
    location.pathname.endsWith("confirm-mha-status-finalise");

  if (location.pathname !== routeFns.patientHome(props.patient.id)) {
    menuFlyoutOptions.push({
      icon: "view",
      name: t("layout.patientBanner.link"),
      link: routeFns.patientHome(props.patient.id),
      disabled: false,
    });
  }

  if (isEditable) {
    menuFlyoutOptions.push({
      icon: "person",
      name: t("layout.patientBanner.edit"),
      link: routeFns.patientEdit(props.patient.id),
      disabled: false,
    });
  }

  const hasPatientMergeAccess = user?.hasPatientMergeAccess ?? false;

  if (hasPatientMergeAccess) {
    menuFlyoutOptions.push({
      icon: "merge",
      name: t("layout.patientBanner.merge"),
      onClick: () => setShowPatientMergeDialog(true),
      disabled: false,
    });
  }

  const mostRecentlyMergedPatient =
    props.patientTimeline?.mergedPatientData.lastMergedPatient;

  // we should only show the undo merge option if the merged patient
  // was last merged more than 30 days ago.
  const isMostRecentlyMergedBefore30Days = dayjs(
    mostRecentlyMergedPatient?.lastMerged,
  ).isBefore(dayjs().add(30, "day"));

  if (
    mostRecentlyMergedPatient &&
    isMostRecentlyMergedBefore30Days &&
    hasPatientMergeAccess
  ) {
    menuFlyoutOptions.push({
      icon: "undo",
      name: t("layout.patientBanner.undoMerge"),
      onClick: () => setShowPatientUndoMergeDialog(true),
      disabled: false,
    });
  }

  if (isMobileView && !disableEditMHAStatusBtn && showEditMhaStatus) {
    menuFlyoutOptions.push({
      icon: "edit",
      name: t("pages.editMHAStatus.editMHAStatus"),
      link: routeFns.editMhaStatusPage(props.patient.id),
      disabled: false,
    });
  }

  // The NHS label is displayed if the NHS number feature is turned on.
  // If the feature is turned off, the label will still be displayed if the patient already has an NHS number recorded.
  const showNhsLabel = shouldShowNhsLabel(nhsNumberEnabled, props.nhsNumber);

  return (
    <>
      <RenderMergeDialogs
        showPatientMergeDialog={showPatientMergeDialog}
        showPatientUndoMergeDialog={showPatientUndoMergeDialog}
        setShowPatientMergeDialog={setShowPatientMergeDialog}
        setShowPatientUndoMergeDialog={setShowPatientUndoMergeDialog}
        reloadPatientTimeline={props.reloadPatientTimeline}
      />

      <StickyContainer
        disablesticky={props.disableSticky ? "true" : undefined}
        issticky={isSticky || isMobileView ? "true" : undefined}
        ismobilemode={isMobileView ? "true" : undefined}
        top={isMobileView ? 58 : isGuestUserSession(user) ? 30 : 0}
        style={{ zIndex: 999 }}
      >
        <BannerContainer
          issticky={isSticky ? "true" : undefined}
          ismobilemode={isMobileView ? "true" : undefined}
        >
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
                "& .MuiIconButton-root": {
                  backgroundColor: theme.palette.secondary.lightBlue,
                },
              },
            }}
            display="flex"
            onClick={() => setIsMainMenuOpen(true)}
          >
            <Box>
              <RenderPatientName
                familyName={props.patient.name.family}
                givenName={props.patient.name.given}
                styleType={mainTextStyles}
                fontSize={18}
              />
            </Box>

            <Box display="flex" marginLeft="auto">
              {showNhsLabel && <RenderNhsNumber nhsNumber={props.nhsNumber} />}
              {!props.isHidden && (
                <Box
                  ref={cardRef}
                  sx={{
                    display: "flex",
                    marginLeft: (theme) => theme.spacing(5.2),
                    alignItems: "center",
                  }}
                  onClick={() => setIsMainMenuOpen(true)}
                >
                  {!!menuFlyoutOptions.length && (
                    <MenuFlyout
                      cardRef={cardRef}
                      options={menuFlyoutOptions}
                      isOpen={isMainMenuOpen}
                      onClose={() => setIsMainMenuOpen(false)}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <Box sx={{ marginTop: "0.5em", width: "100%" }}>
              <HorizontalLine noMargin={true} />
            </Box>
          </CSSTransition>
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "0.5em",
                }}
              >
                <RenderPatientAge
                  patientAge={
                    patientAge ? patientAge : t("layout.patientBanner.unknown")
                  }
                  styleType={mainTextStyles}
                />

                <Spacer />

                <RenderPatientAddress
                  address={props.patient.address.address}
                  styleType={mainTextStyles}
                />

                <Spacer />
                <RenderPostCode
                  postalCode={props.patient.address.postalCode}
                  styleType={mainTextStyles}
                />

                <Spacer />

                <RenderDateOfBirth
                  dateOfBirth={
                    props.patient.dateOfBirth
                      ? dayjs(props.patient.dateOfBirth).format("DD MMM YYYY")
                      : t("layout.patientBanner.unknown")
                  }
                  styleType={mainTextStyles}
                />

                <Spacer />
              </Box>
              {props.externalPatientLinks !== undefined && (
                <Stack
                  direction={"column"}
                  gap={"0.5rem"}
                  sx={{ marginTop: "0.5rem" }}
                >
                  {props.externalPatientLinks.links
                    .filter(
                      (externalPatientLink) => externalPatientLink.canPull,
                    )
                    .map((externalPatientLink) => {
                      return (
                        <ManualPullButton
                          key={JSON.stringify(externalPatientLink)}
                          externalSystemType={
                            externalPatientLink.externalSystemType
                          }
                          externalSystemDisplayName={
                            externalPatientLink.externalSystemDisplayName
                          }
                          dateLastProcessed={
                            externalPatientLink.dateLastProcessed
                          }
                          onSync={async () => {
                            await props.externalPatientLinks!.onSync(
                              externalPatientLink,
                              "manual pull",
                            );
                          }}
                        />
                      );
                    })}
                </Stack>
              )}
            </Box>
          </CSSTransition>
          {patientMhaStatusEnabled && (
            <CSSTransition
              in={isNotSticky}
              timeout={300}
              classNames={{
                enter: "fade-enter",
                enterActive: "fade-enter-active",
                exit: "fade-exit",
                exitActive: "fade-exit-active",
              }}
              unmountOnExit
            >
              <>
                <Box sx={{ marginTop: "0.5em", width: "100%" }}>
                  <HorizontalLine noMargin={true} />
                </Box>
                <Box sx={{ marginTop: "1em" }}>
                  {isMobileView ? (
                    <RenderMhaStatusInfoBox
                      mhaStatus={mhaStatus}
                      showUnknownMhaStatus={props.showUnknownMhaStatus}
                    />
                  ) : (
                    <PatientStateDisplay
                      state={
                        mhaStatus?.states[0] ?? {
                          id: v4(),
                          isLatest: true,
                          effectiveDateTime: dayjs().toISOString(),
                          patientId: props.patient.id,
                          state: { mhaStatus: { status: MhaStatus.Unknown } },
                        }
                      }
                      disableEditMHAStatusBtn={disableEditMHAStatusBtn}
                      showEditMhaStatus={showEditMhaStatus}
                    />
                  )}
                </Box>
              </>
            </CSSTransition>
          )}
        </BannerContainer>

        {!props.isHidden && (
          <CSSTransition
            in={showExtendedContent}
            timeout={300}
            classNames={{
              enter: "fade-enter",
              enterActive: "fade-enter-active",
              exit: "fade-exit",
              exitActive: "fade-exit-active",
            }}
            unmountOnExit
          >
            <PatientCrisisInfo />
          </CSSTransition>
        )}
        {!props.isHorizontalLineHidden && (
          <Box sx={{ my: isMobileView ? 2 : 3 }}>{/*<HorizontalLine />*/}</Box>
        )}
      </StickyContainer>
    </>
  );
}

export function CompactedPatientBanner(props: PatientBannerProps) {
  const theme = useTheme();

  const { t } = useTranslation();

  const mainTextStyles = {
    color: "text.primary",
    fontSize: theme.spacing(1.5),
  };

  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user;

  const nhsNumberEnabled =
    user?.sessionOrganisationConfiguration?.nhsNumberEnabled ?? false;

  const patientMhaStatusEnabled =
    user?.sessionOrganisationConfiguration?.patientStateEnabled ?? false;

  const showNhsLabel = shouldShowNhsLabel(nhsNumberEnabled, props.nhsNumber);
  const patientAge = dayjs().diff(dayjs(props.patient.dateOfBirth), "year");

  const { mhaStatus } = useMhaStatus({
    patientId: props.patient.id,
  });

  const [showPatientMergeDialog, setShowPatientMergeDialog] =
    React.useState(false);

  const [showPatientUndoMergeDialog, setShowPatientUndoMergeDialog] =
    React.useState(false);

  return (
    <>
      <RenderMergeDialogs
        showPatientMergeDialog={showPatientMergeDialog}
        showPatientUndoMergeDialog={showPatientUndoMergeDialog}
        setShowPatientMergeDialog={setShowPatientMergeDialog}
        setShowPatientUndoMergeDialog={setShowPatientUndoMergeDialog}
      />

      <BannerContainer sx={{ minHeight: "170px" }}>
        <Box display="flex">
          <Box>
            <RenderPatientName
              familyName={props.patient.name.family}
              givenName={props.patient.name.given}
              styleType={mainTextStyles}
              fontSize={12}
            />
          </Box>

          <Box display="flex" alignItems={"flex-end"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                {showNhsLabel && (
                  <>
                    <Spacer />
                    <RenderNhsNumber nhsNumber={props.nhsNumber} />
                  </>
                )}

                <Spacer />

                <RenderDateOfBirth
                  dateOfBirth={
                    props.patient.dateOfBirth
                      ? dayjs(props.patient.dateOfBirth).format("DD MMM YYYY")
                      : t("layout.patientBanner.unknown")
                  }
                  styleType={compactTextStyles}
                />

                <Spacer />
                <RenderPatientAge
                  patientAge={
                    patientAge ? patientAge : t("layout.patientBanner.unknown")
                  }
                  styleType={mainTextStyles}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ my: "0.5em" }}>
          <HorizontalLine noMargin={true} />
        </Box>
        <Box display="flex" alignItems={"center"}>
          <RenderPatientAddress
            address={props.patient.address.address}
            styleType={mainTextStyles}
          />

          <Box display="flex" alignItems={"flex-end"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "0.5em",
                }}
              >
                <Spacer />
                <RenderPostCode
                  postalCode={props.patient.address.postalCode}
                  styleType={mainTextStyles}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </BannerContainer>

      {patientMhaStatusEnabled && (
        <Box sx={{ my: theme.spacing(2), ml: theme.spacing(2) }}>
          <RenderMhaStatusInfoBox
            mhaStatus={mhaStatus}
            showUnknownMhaStatus={props.showUnknownMhaStatus}
          />
        </Box>
      )}
    </>
  );
}

function RenderMergeDialogs({
  showPatientMergeDialog,
  showPatientUndoMergeDialog,
  setShowPatientMergeDialog,
  setShowPatientUndoMergeDialog,
  reloadPatientTimeline,
}: {
  showPatientMergeDialog: boolean;
  showPatientUndoMergeDialog: boolean;
  setShowPatientMergeDialog: (show: boolean) => void;
  setShowPatientUndoMergeDialog: (show: boolean) => void;
  reloadPatientTimeline?: () => void;
}) {
  return (
    <>
      {showPatientMergeDialog && (
        <PatientMergeDialog
          closeMergeDialog={() => setShowPatientMergeDialog(false)}
        />
      )}

      {showPatientUndoMergeDialog && (
        <PatientUndoMergeDialog
          closeUndoMergeDialog={() => setShowPatientUndoMergeDialog(false)}
          reloadPatientTimeline={reloadPatientTimeline}
        />
      )}
    </>
  );
}

function RenderPatientAddress({
  address,
  styleType,
}: {
  address: string;
  styleType: MainTextStyles;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={getSmallTextStyles}>
        {t("layout.patientBanner.address")}
      </Typography>
      <Typography sx={{ ...styleType }}>{address}</Typography>
    </Box>
  );
}

function RenderPatientAge({
  patientAge,
  styleType,
}: {
  patientAge: number | string;
  styleType: MainTextStyles;
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={getSmallTextStyles}>
        {t("layout.patientBanner.age")}
      </Typography>
      <Typography sx={{ ...styleType }}>{patientAge}</Typography>
    </Box>
  );
}

function RenderDateOfBirth({
  dateOfBirth,
  styleType,
}: {
  dateOfBirth: string;
  styleType: MainTextStyles;
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={getSmallTextStyles}>
        {t("layout.patientBanner.dateOfBirth")}
      </Typography>
      <Typography sx={{ ...styleType }}>{dateOfBirth}</Typography>
    </Box>
  );
}

function RenderPostCode({
  postalCode,
  styleType,
}: {
  postalCode: string;
  styleType: MainTextStyles;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={getSmallTextStyles}>
        {t("layout.patientBanner.postcode")}
      </Typography>
      <Typography sx={{ ...styleType }}>
        {postalCode ? postalCode : t("layout.patientBanner.unknown")}
      </Typography>
    </Box>
  );
}

function RenderPatientName({
  familyName,
  givenName,
  styleType,
  fontSize,
}: {
  familyName: string;
  givenName: string;
  styleType: MainTextStyles;
  fontSize: number;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          fontSize: theme.spacing(1.55),
          color: "primary.hint",
          marginBottom: "0.2em",
        }}
      >
        <Typography sx={{ ...getSmallTextStyles(theme) }}>
          {t("layout.patientBanner.name")}
        </Typography>
      </Box>
      <Typography
        sx={{
          ...styleType,
          fontWeight: 600,
          fontSize,
        }}
      >
        {familyName}, {givenName}
      </Typography>
    </>
  );
}

function RenderNhsNumber({ nhsNumber }: { nhsNumber?: string }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ ...getSmallTextStyles(theme) }}>
        {t("layout.patientBanner.nhsNumber")}
      </Typography>

      <Typography sx={{ ...compactTextStyles(theme) }}>
        {nhsNumber ?? t("common.unknown")}
      </Typography>
    </Box>
  );
}

function RenderMhaStatusInfoBox({
  mhaStatus,
  showUnknownMhaStatus,
}: {
  mhaStatus:
    | {
        states: PatientStateWithContext[];
      }
    | undefined;
  showUnknownMhaStatus?: boolean;
}) {
  if (mhaStatus?.states[0] && !showUnknownMhaStatus) {
    return (
      <MhaStatusInfoBox
        title="MHA Status"
        subtitle={infoBoxMhaStatusTitle(
          mhaStatus.states[0],
          getActiveMhaStatus(mhaStatus.states[0].state!, dayjs().toISOString()),
        )}
        testId="mhaStatus"
      />
    );
  } else {
    return (
      <MhaStatusInfoBox title="MHA status" subtitle={MhaStatus.Incomplete} />
    );
  }
}
