import {
  DashboardData,
  getBaseFormTemplate,
  isGuestUser,
  MhaStatus,
  nonEmptyString,
} from "@aspire/common";
import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Formik } from "formik";
import { chain, uniq } from "lodash-es";
import React, { useEffect, useState } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { object } from "yup";
import {
  Banner,
  BannerList,
  Button,
  ButtonIcon,
  DateField,
  Dropdown,
  FormTitle,
  PopupDialog,
  renderSuccessToast,
  TextField,
  Typeahead,
} from "~/components/design-system/index.js";
import { routeFns } from "~/routes.js";
import { api, apiHooks } from "../api.js";
import DropdownTreeSelectContainer from "../components/DropdownTreeSelectContainer.js";
import { LoggedInUserContext } from "../Contexts.js";
import { triggerDownload } from "../util.js";
import { DefaultPageProps } from "./defaultProps.js";
import "./index.css";

type Context = { type: "organisation" | "team"; id: string; name: string };

function SignedFormsByTypeChart({
  dashboardData,
}: {
  dashboardData: DashboardData;
}) {
  const expandedFormCounts =
    dashboardData &&
    dashboardData.formCounts
      .map((c) => ({
        ...c,
        ...getBaseFormTemplate(c.formTemplateId)!,
      }))
      .sort((f1, f2) => (f1.category > f2.category ? -1 : 1));

  const buildData = (
    dashboardData: DashboardData | null | undefined,
  ): {
    innerData: { name: string; value: number }[];
    outerData: { name: string; value: number }[];
  } => {
    if (!dashboardData || dashboardData.totalCount === 0)
      return { innerData: [], outerData: [] };

    return {
      innerData: chain(expandedFormCounts)
        .groupBy("category")
        .map((value, key) => {
          let count = 0;
          for (const x of value) {
            count += x.count;
          }
          return { name: key, value: count };
        })
        .value(),
      outerData: expandedFormCounts!.map((f) => ({
        name: f.formName,
        value: f.count,
      })),
    };
  };

  const { innerData, outerData } = buildData(dashboardData);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (args: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
    name: string;
    value: number;
  }) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, name } = args;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fontSize={"smaller"}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${name}`}
      </text>
    );
  };
  return (
    <Container
      sx={{
        width: "80vw",
        height: "70vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={innerData}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={"65%"}
            fill="#8884d8"
            label={renderCustomizedLabel}
            labelLine={false}
          />
          <Pie
            data={outerData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={"70%"}
            outerRadius={"80%"}
            fill="#82ca9d"
            label={({ name, percent }: { name: string; percent: number }) =>
              `${name} (${Math.floor(percent * 100)}%)`
            }
          />
        </PieChart>
      </ResponsiveContainer>
      <Box sx={{ width: 200, height: "100%" }}>
        {dashboardData &&
          uniq(expandedFormCounts!.map((f) => f.category))
            .sort((c1, c2) => (c1 < c2 ? -1 : 1))
            .map((category) => {
              const forms = expandedFormCounts!.filter(
                (f) => f.category === category,
              );

              const totalCount = forms
                .map((f) => f.count)
                .reduce((y1, y2) => y1 + y2);

              return (
                <>
                  <h4>
                    {category} ({totalCount})
                  </h4>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        {forms
                          .sort((f1, f2) =>
                            f1.formName < f2.formName ? -1 : 1,
                          )
                          .map((row) => (
                            <TableRow
                              key={row.formName}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell scope="row" width={"70%"}>
                                {row.formName}
                              </TableCell>
                              <TableCell
                                width={"30%"}
                              >{`${row.count}`}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              );
            })}
      </Box>
    </Container>
  );
}

function AdmissionsDashboard({
  dashboardData,
}: {
  dashboardData: DashboardData;
}) {
  const gridEntryCss = {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flexBasis: "50%",
    minHeight: "350px",
    width: "50%",
    padding: "2em",
    display: "flex",
  };

  const admissionsHistogram: { days: string; count: number }[] = [
    ...new Array(20),
  ].map((_, i) => ({ days: `${i}`, count: 0 }));

  for (const timeDifference of dashboardData.admissions
    .timesBetweenAdmissionAndExamination) {
    const existing = admissionsHistogram.find(
      (h) => h.days === `${timeDifference}`,
    );

    if (existing) {
      existing.count++;
    } else {
      admissionsHistogram.push({ days: `${timeDifference}`, count: 1 });
    }
  }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box sx={gridEntryCss}>
        <h4>Admissions by Section Used</h4>
        <TableContainer component={Paper} sx={{ height: "initial" }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Section</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(dashboardData.admissions.sections).map(
                ([section, count]) => (
                  <TableRow
                    key={`Section ${section}`}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      scope="row"
                      width={"70%"}
                    >{`Section ${section}`}</TableCell>
                    <TableCell width={"30%"}>{`${count}`}</TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={gridEntryCss}>
        <h4>Admitted Patient Age Distribution</h4>
        <ResponsiveContainer width="100%">
          <BarChart
            width={500}
            height={300}
            data={Object.entries(dashboardData.admissions.patientAges).map(
              ([range, count]) => ({ range, count }),
            )}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="range" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" name={"Age Bracket"} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={gridEntryCss}>
        <h4>Time between Examination and Admission</h4>
        <ResponsiveContainer width="100%">
          <BarChart
            width={500}
            height={300}
            data={admissionsHistogram.sort((h1, h2) =>
              parseInt(h1.days) < parseInt(h2.days) ? -1 : 1,
            )}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="days" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" name={"Number of Days"} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={gridEntryCss}>
        <h4>Nearest Relative Status</h4>
        <TableContainer component={Paper} sx={{ height: "initial" }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell scope="row" width={"70%"}>
                  NR unknown (Section 2)
                </TableCell>
                <TableCell
                  width={"30%"}
                >{`${dashboardData.admissions.nearestRelative.unknown}`}</TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell scope="row" width={"70%"}>
                  NR informed (Section 2)
                </TableCell>
                <TableCell
                  width={"30%"}
                >{`${dashboardData.admissions.nearestRelative.informed}`}</TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell scope="row" width={"70%"}>
                  NR consulted (Section 3)
                </TableCell>
                <TableCell
                  width={"30%"}
                >{`${dashboardData.admissions.nearestRelative.consulted}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

function SignedFormsByDateChart({
  dashboardData,
}: {
  dashboardData: DashboardData;
}) {
  return (
    <Container
      sx={{
        width: "80vw",
        height: "70vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <ResponsiveContainer width="100%">
        <BarChart
          width={500}
          height={300}
          data={dashboardData.dateRange}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="count"
            fill="#8884d8"
            name={"First Signature (count)"}
          />
        </BarChart>
      </ResponsiveContainer>
      <Box sx={{ width: 200, height: "100%" }}></Box>
    </Container>
  );
}

type DashboardType = "forms-by-date" | "forms-by-type" | "admissions";

function Dashboard({
  dashboardData,
  dashboardType,
}: {
  dashboardData: DashboardData;
  dashboardType: DashboardType;
}) {
  switch (dashboardType) {
    case "forms-by-date":
      return <SignedFormsByDateChart dashboardData={dashboardData} />;
    case "forms-by-type":
      return <SignedFormsByTypeChart dashboardData={dashboardData} />;
    case "admissions":
      return <AdmissionsDashboard dashboardData={dashboardData} />;
  }
}

type DownloadRequestPid = { email: string; reasonForDownloading: string };

export function DashboardPage({}: DefaultPageProps) {
  const userContext = React.useContext(LoggedInUserContext);
  const { t } = useTranslation();

  const user = userContext?.user;

  const admissionsEnabled =
    user?.sessionOrganisationConfiguration?.mhaAdmissionsEnabled;

  // TODO: work out actual permissioning for MHA dashboard
  const mhaPatientStatusEnabled =
    user?.sessionOrganisationConfiguration?.patientStateEnabled;
  const isMha = user?.sessionContext?.teamType === "mha";
  const showMhaDashboardLink = isMha && mhaPatientStatusEnabled;

  const [{ data: orgsAndTeams, loading: orgsAndTeamsLoading }] =
    apiHooks.reports.availableOrgsAndTeams();

  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().startOf("year"));
  const [toDate, setToDate] = useState<Dayjs>(dayjs());
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);

  const [selectedContexts, setSelectedContexts] = useState<Context[]>([]);

  useEffect(() => {
    if (orgsAndTeams) {
      setSelectedContexts(
        orgsAndTeams.orgTree.map((o) => ({
          type: o.type,
          id: o.value,
          name: o.label,
        })),
      );
    }
  }, [orgsAndTeams]);

  const [reportLoading, setReportLoading] = useState(false);
  const [selectedDashboard, setSelectedDashboard] =
    useState<DashboardType>("forms-by-date");

  const params = {
    fromDate: fromDate.format("YYYY-MM-DD"),
    toDate: toDate.format("YYYY-MM-DD"),
    organisationIds: selectedContexts
      .filter((c) => c.type === "organisation")
      .map((c) => c.id),
    teamIds: selectedContexts.filter((c) => c.type === "team").map((c) => c.id),
  };

  const [
    {
      data: dashboardData,
      loading: dashboardDataLoading,
      error: dashboardError,
    },
  ] = apiHooks.reports.dashboardData(params);

  const [includePid, setIncludePid] = useState(false);

  const [showPersonalDataDownloadDialog, setShowPersonalDataDownloadDialog] =
    useState(false);

  const generateSignedFormsCsv = async (
    downloadRequest?: DownloadRequestPid | undefined,
  ) => {
    setSubmitError(undefined);
    const { email, reasonForDownloading } = downloadRequest || {};

    setReportLoading(true);

    const result = await api.reports.generateSignedFormsCsv({
      ...params,
      includePid,
      email,
      reasonForDownloading,
    });

    if (result.status === 200) {
      triggerDownload(
        `data:text/csv;base64,${result.data}`,
        `${fromDate.format("YYYY-MM-DD")}-${toDate.format("YYYY-MM-DD")}.csv`,
      );
      setReportLoading(false);

      renderSuccessToast({
        message: `download successful`,
      });
      setShowPersonalDataDownloadDialog(false);
    } else {
      setSubmitError(
        (result.data as { reason?: string })?.reason || "Unknown error",
      );
    }
  };

  const onSave = (downloadRequest: DownloadRequestPid) => {
    generateSignedFormsCsv(downloadRequest);
  };

  if (!dashboardDataLoading && (dashboardError || !dashboardData)) {
    return (
      <Box>
        {dashboardError?.message
          ? `${dashboardError.message} - ${t(
              "errors.dashboardLoadingErrorMessage",
            )}`
          : `${t("errors.unknownError")} - ${t(
              "errors.dashboardLoadingErrorMessage",
            )}`}
      </Box>
    );
  }

  return orgsAndTeamsLoading ? (
    <></>
  ) : (
    <>
      <Box
        sx={{
          width: "100%",
          marginTop: "2em",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            mr: 2,
            mb: "27px",
            flexBasis: "100%",
            minWidth: "90vh",
            display: "flex",
          }}
        >
          <DropdownTreeSelectContainer
            disabled={
              orgsAndTeams?.orgTree.length === 1 &&
              orgsAndTeams?.orgTree[0].children.length === 0
            }
            mode={"multiSelect"}
            texts={{ placeholder: " " }}
            data={
              orgsAndTeams?.orgTree.map((x) => ({ ...x, checked: true })) || []
            }
            onChange={(currentNode, selectedNodes) => {
              const contexts = selectedNodes.map((n) => ({
                type: n.type,
                name: n.label,
                id: n.value,
              }));
              setSelectedContexts(contexts);
            }}
          />
          {showMhaDashboardLink && (
            <a
              href={routeFns.mhaStatusDashboardPage(0, {
                statuses: [
                  MhaStatus.Incomplete,
                  MhaStatus.Expired,
                  MhaStatus.Section17A,
                  MhaStatus.Section2,
                  MhaStatus.Section3,
                  MhaStatus.Section4,
                  MhaStatus.Section5_2,
                  MhaStatus.Section5_4,
                ],
              })}
            >
              <Button
                endIcon={ButtonIcon.arrow}
                sx={{ ml: "1em" }}
                label="Go to MHA Status Dashboard"
              />
            </a>
          )}
        </Box>
        <Box sx={{ marginRight: "1em", width: 250 }}>
          <Dropdown
            name={"chart"}
            selectedValue={selectedDashboard}
            values={[
              { value: "forms-by-type", label: "Signed Forms by Type" },
              { value: "forms-by-date", label: "Signed Forms By Date" },

              // Don't show the MHA admissions dashboard if admissions are not enabled
              // for the logged in team
              ...(admissionsEnabled
                ? [{ value: "admissions", label: "Admissions" }]
                : []),
            ]}
            onChange={(value) => {
              setSelectedDashboard(value);
            }}
          />
        </Box>
        <Box sx={{ marginRight: "1em" }}>
          <DateField
            name={"from"}
            label={"From Date"}
            value={fromDate}
            maxDate={dayjs()}
            onChange={(e) => e && setFromDate(e)}
          ></DateField>
        </Box>
        <Box>
          <DateField
            name={"from"}
            label={"To Date"}
            value={toDate}
            maxDate={dayjs()}
            onChange={(e) => e && setToDate(e)}
          ></DateField>
        </Box>
        <Box sx={{ display: "flex", height: 100, alignItems: "center" }}>
          {user?.hasPidAccess ? (
            <Box sx={{ ml: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includePid}
                    onChange={() => setIncludePid(!includePid)}
                  />
                }
                label={"Include PID in CSV"}
              />
            </Box>
          ) : (
            <Box sx={{ ml: 3 }}></Box>
          )}
          <Box sx={{ ml: 1 }}>
            <Button
              startIcon={ButtonIcon.download}
              disabled={reportLoading || selectedContexts.length === 0}
              label={"Download As Spreadsheet"}
              onClick={
                includePid
                  ? () => setShowPersonalDataDownloadDialog(true)
                  : () => generateSignedFormsCsv()
              }
            />
          </Box>
        </Box>
      </Box>
      {dashboardDataLoading ? (
        <CircularProgress />
      ) : (
        <Dashboard
          dashboardData={dashboardData!}
          dashboardType={selectedDashboard}
        />
      )}
      {showPersonalDataDownloadDialog && (
        <RequestPersonalDataDownloadDialog
          closeFn={() => setShowPersonalDataDownloadDialog(false)}
          onSave={onSave}
          submitError={submitError}
        />
      )}
    </>
  );
}

function RequestPersonalDataDownloadDialog({
  closeFn,
  onSave,
  submitError,
}: {
  closeFn: () => void;
  onSave: (params: DownloadRequestPid) => void;
  submitError?: string | undefined;
}) {
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = React.useState<any>([]);

  const initialValues = {
    reasonForDownloading: "",
    email: "",
  };

  const personalDataDownloadDataSchema = object({
    email: nonEmptyString
      .email("Please enter a valid email")
      .required("Please enter an email"),

    reasonForDownloading: nonEmptyString.required("Please enter a reason"),
  });

  return (
    <Formik
      validationSchema={personalDataDownloadDataSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        const { email, reasonForDownloading } = values;
        const downloadRequestData = { email, reasonForDownloading };
        onSave(downloadRequestData);
      }}
    >
      {(formikValues) => {
        const { values, errors, setValues, touched, setTouched, submitForm } =
          formikValues;

        return (
          <PopupDialog open={true} onClose={closeFn}>
            <Box display="flex" justifyContent="space-between" sx={{ mb: 4 }}>
              <FormTitle
                subtitleText={t("pages.patientHome.launchForm.confirmAction")}
                hasContainerMarginBottom={false}
                hasTitleBottomMargin={false}
              />
              <IconButton
                aria-label={t("buttonLabels.close")}
                onClick={closeFn}
              >
                <Close sx={{ color: "primary.hint" }} fontSize="small" />
              </IconButton>
            </Box>
            <Box sx={{ mb: 4 }}>
              <Banner
                bannerType={BannerList.WARNING}
                title={t("pages.dashboard.popupDialog.warningMessage")}
              />
            </Box>
            <Box sx={{ mb: 0.5 }}>
              {submitError && (
                <Box sx={{ my: 2 }}>
                  <Banner title={submitError} bannerType={BannerList.ERROR} />
                </Box>
              )}
              <TextField
                useFullWidth={true}
                value={values.reasonForDownloading}
                onBlur={() => {
                  setTouched({ ...touched, reasonForDownloading: true });
                }}
                showHelperText={!!errors.reasonForDownloading}
                errorMessage={
                  touched.reasonForDownloading
                    ? (errors.reasonForDownloading as string)
                    : ""
                }
                name={"reasonForDownloading"}
                label={
                  "Please enter a reason for why you are downloading personal identifiable data"
                }
                onChange={(e) =>
                  setValues({ ...values, reasonForDownloading: e })
                }
                rows="5"
                multiline={true}
              />
            </Box>
            <Box sx={{ mb: 0.5 }}>
              <Typeahead
                label={"Please enter the email address of your manager "}
                options={searchResults}
                name="recipientEmail"
                onBlur={() => {
                  setTouched({ ...touched, email: true });
                }}
                errorMessage={touched.email ? (errors.email as string) : ""}
                showHelperText={!!errors.email}
                inputValue={values.email}
                optionsKey="name"
                getOptionLabel={(option: any) =>
                  typeof option === "string"
                    ? option
                    : `${option.name} (${option.email})`
                }
                onInputChange={async (value: string) => {
                  const match = searchResults.find(
                    (u: any) => value === `${u.name} (${u.email})`,
                  );
                  if (match) {
                    setValues({ ...values, email: match.email });
                  } else {
                    setValues({ ...values, email: value });
                  }
                  if (value.length > 1) {
                    const results = await api.users.search(value, 10, 0);

                    const filtered = results.data?.results.filter(
                      (u) => !isGuestUser(u),
                    );

                    setSearchResults(filtered);

                    if (match) {
                      setValues({ ...values, email: match.email });
                    }
                  } else {
                    setSearchResults([]);
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                mt: 4,
              }}
            >
              <Button
                variant="outlined"
                label={t("buttonLabels.close")}
                onClick={closeFn}
              />
              <Button label={t("buttonLabels.confirm")} onClick={submitForm} />
            </Box>
          </PopupDialog>
        );
      }}
    </Formik>
  );
}
