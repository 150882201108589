import { AdminCreateUserPage } from "./pages/Admin/AdminCreateUserPage.js";
import { AdminPage } from "./pages/Admin/AdminPage.js";
import { TeamCreateEditPage } from "./pages/Admin/TeamCreateEditPage.js";
import { CaseloadPage } from "./pages/Caseload/Caseload.js";
import { ContextSelectorPage } from "./pages/ContextSelector/ContextSelector.js";
import { DashboardPage } from "./pages/DashboardPage.js";
import { FormDraftCompletionPage } from "./pages/FormDraft/FormDraftCompletionPage.js";
import { FormDraftSignPage } from "./pages/FormDraft/FormDraftSignPage.js";
import { FormPdfPage } from "./pages/FormPdfPage.js";
import { FormContextPage } from "./pages/FormProgressPage/FormContextPage.js";
import { FormReviewPage } from "./pages/FormReviewPage.js";
import { GuestUserLoginPage } from "./pages/GuestUserLoginPage.js";
import { HomePage } from "./pages/HomePage.js";
import NotFound from "./pages/NotFound.js";
import { NotificationsPage } from "./pages/NotificationsPage.js";
import { OrganisationHomePage } from "./pages/OrganisationHomePage.js";
import { PatientHomePage } from "./pages/PatientHomePage.js";
import { UserProfile } from "./pages/UserProfile/UserProfile.js";

import {
  AuditTrailEntryType,
  buildFormPath,
  MhaDashboardDataParams,
  SupportingFormsDetails,
  WorkItem,
} from "@aspire/common";
import { Dayjs } from "dayjs";
import { TeamEditPage } from "~/pages/ExternalAdmin/Teams/TeamEdit.js";
import { MhaStatusDashboardPage } from "~/pages/MhaStatusDashboard/MhaStatusDashboardPage.js";
import { AdminConvertGuestUserPage } from "./pages/Admin/AdminConvertGuestUserPage.js";
import { BulkUserConfigurationPage } from "./pages/Admin/BulkUserConfigurationPage.js";
import { OrganisationCreateEdit } from "./pages/Admin/OrganisationCreateEdit.js";
import { RioInstanceCreateEdit } from "./pages/Admin/RioInstanceCreateEdit.js";
import { TermsAndConditionsCreateEditPage } from "./pages/Admin/TermsAndConditionsCreateEditPage.js";
import { UserMembershipsPage } from "./pages/Admin/UserMembershipsPage.js";
import { DeliberatelyThrowsAnErrorPage } from "./pages/DeliberatelyThrowsAnErrorPage.js";
import { EditMhaStatusPage } from "./pages/EditMhaStatus/EditMhaStatusPage.js";
import ErrorPage from "./pages/ErrorPage.js";
import { TeamsOverviewPage } from "./pages/ExternalAdmin/Teams/TeamsOverviewPage.js";
import { UserOverviewPage } from "./pages/ExternalAdmin/Users/UserOverviewPage.js";
import FormUpload from "./pages/FormUpload/FormUpload.js";
import { LoginPage } from "./pages/LoginPage.js";
import { PatientContextLaunchPage } from "./pages/PatientIndex/PatientContextLaunchPage.js";
import { PatientEditPage } from "./pages/PatientIndex/PatientEditPage.js";
import {
  PatientCreatePage,
  PatientPage,
} from "./pages/PatientIndex/PatientSearch.js";

function createSearchParams(params: object) {
  return new URLSearchParams(
    Object.entries(params)
      .filter(([k, v]) => v)
      .flatMap(([key, values]) =>
        Array.isArray(values)
          ? values.map((value) => [key, value])
          : [[key, values]],
      ),
  );
}

export const rawRoutesBase = {
  home: {
    path: "/",
    exact: true,
    component: HomePage,
    breadcrumb: "Home",
    buildFn: () => `/`,
  },
  testErrorPage: {
    path: "/test-error-page",
    exact: false,
    component: DeliberatelyThrowsAnErrorPage,
    breadcrumb: "Home",
    buildFn: () => `/test-error-page`,
  },
  errorPage: {
    path: "/error-page",
    exact: false,
    component: ErrorPage,
    breadcrumb: "Home",
    buildFn: () => `/error-page`,
  },
  dashboard: {
    path: "/dashboard",
    exact: true,
    component: DashboardPage,
    breadcrumb: "Dashboard",
    buildFn: () => `/dashboard`,
  },
  caseloadUser: {
    path: "/caseload/user-caseload",
    exact: true,
    component: CaseloadPage,
    breadcrumb: "User Caseload",
    buildFn: () => `/caseload/user-caseload`,
  },
  caseloadCaseload: {
    path: "/caseload/caseload",
    exact: true,
    component: CaseloadPage,
    breadcrumb: "Caseload",
    buildFn: () => `/caseload/caseload`,
  },
  caseloadArchive: {
    path: "/caseload/archive",
    exact: true,
    component: CaseloadPage,
    breadcrumb: "Archive",
    buildFn: () => `/caseload/archive`,
  },
  notifications: {
    path: "/notifications",
    exact: true,
    component: NotificationsPage,
    breadcrumb: "Notifications",
    buildFn: () => `/notifications`,
  },
  teamCreateEdit: {
    path: "/teams/:teamId/edit",
    exact: true,
    component: TeamCreateEditPage,
    breadcrumb: "Team Edit",
    buildFn: (teamId: string) => `/teams/${teamId}/edit`,
  },
  managerTeamEdit: {
    path: "/manager/team/:teamId/edit",
    exact: true,
    component: TeamEditPage,
    breadcrumb: "Team Edit",
    buildFn: (teamId: string) => `/manager/team/${teamId}/edit`,
  },
  organisationHome: {
    path: "/organisations/:organisationId",
    exact: true,
    component: OrganisationHomePage,
    breadcrumb: "Organisation",
    buildFn: (orgId: string) => `/organisations/${orgId}`,
  },
  organisationCreateEdit: {
    path: "/organisations/:organisationId/edit",
    exact: true,
    component: OrganisationCreateEdit,
    breadcrumb: "Organisation Edit",
    buildFn: (orgId: string) => `/organisations/${orgId}/edit`,
  },
  formUploadSelectedTemplate: {
    path: "/patients/:patientId/forms/:formContextId/form-upload-select-template",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form Upload",
    buildFn: (formId: string, patientId: string) =>
      `${buildFormPath(formId, patientId)}/form-upload-select-template`,
  },
  formUpload: {
    path: "/patients/:patientId/forms/:formContextId/form-upload/:formTemplateIdFromUrl/:supportingFormsType?",
    exact: true,
    component: FormUpload,
    breadcrumb: "Form Upload",
    buildFn: (
      formId: string,
      patientId: string,
      formTemplateIdFromUrl?: string,
      supportingFormsDetails?: SupportingFormsDetails | undefined,
    ) => {
      let url = `${buildFormPath(
        formId,
        patientId,
      )}/form-upload/${formTemplateIdFromUrl}`;

      if (supportingFormsDetails) {
        if (supportingFormsDetails.type === "provided") {
          const formIdsParam = supportingFormsDetails.formIds.join(",");
          url += `/formIds=${formIdsParam}`;
        } else if (supportingFormsDetails.type === "not-provided") {
          url += `/reason=${supportingFormsDetails.reason}`;
        }
      }

      return url;
    },
  },
  patientSearch: {
    path: "/patients",
    exact: true,
    component: PatientPage,
    breadcrumb: "Patient Search",
    buildFn: () => `/patients`,
  },
  patientCreate: {
    path: "/patients/create",
    exact: true,
    component: PatientCreatePage,
    breadcrumb: "Patient Create",
    buildFn: () => `/patients/create`,
  },
  patientCreateFromExternalId: {
    path: "/patients/context-launch",
    exact: true,
    component: PatientContextLaunchPage,
    breadcrumb: "Patient Context Launch",
    buildFn: () => `/patients/context-launch`,
  },
  patientHome: {
    path: "/patients/:patientId",
    exact: true,
    component: PatientHomePage,
    breadcrumb: "Patient",
    buildFn: (patientId: string) => `/patients/${patientId}`,
  },

  patientHomeActivityLog: {
    path: "/patients/:patientId/activity-log",
    exact: true,
    component: PatientHomePage,
    breadcrumb: "Form",
    buildFn: (patientId: string) => `/patients/${patientId}/activity-log`,
  },
  patientEdit: {
    path: "/patients/:patientId/edit",
    exact: true,
    component: PatientEditPage,
    breadcrumb: "Patient",
    buildFn: (patientId: string) => `/patients/${patientId}/edit`,
  },

  patientFormLaunch: {
    path: "/patients/:patientId/form-launch/:category/:id?",
    exact: true,
    component: PatientHomePage,
    breadcrumb: "Patient",
    buildFn: (patientId: string, category?: string, id?: string) =>
      `/patients/${patientId}/form-launch/${category}${id ? `/${id}` : ""}`,
  },

  formContextPage: {
    path: "/patients/:patientId/forms/:formContextId",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (formId: string, patientId: string) =>
      buildFormPath(formId, patientId),
  },
  formContextPageActivityLog: {
    path: "/patients/:patientId/forms/:formContextId/activity-log",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (formId: string, patientId: string) =>
      `${buildFormPath(formId, patientId)}/activity-log`,
  },
  formContextReasonForNotProceedingDialog: {
    path: "/patients/:patientId/forms/:formContextId/decline",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (formId: string, patientId: string) =>
      `${buildFormPath(formId, patientId)}/decline`,
  },

  formContextReopenAdmissionDialog: {
    path: "/patients/:patientId/forms/:formContextId/reopen",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (formId: string, patientId: string) =>
      `${buildFormPath(formId, patientId)}/reopen`,
  },
  formContextPageSendRequest: {
    path: "/patients/:patientId/forms/:formContextId/request",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (
      formId: string,
      patientId: string,
      params: {
        requestType?: WorkItem["requestType"] | "share";
        formId?: string;
        formPart?: string;
        title?: string;
        infoMessage?: string;
      } = {},
    ) => {
      const base = `${buildFormPath(formId, patientId)}/request`;

      if (Object.keys(params).length > 0) {
        const query = new URLSearchParams(params);
        return `${base}?${query.toString()}`;
      }
      return base;
    },
  },
  formContextPageSuccessDialog: {
    path: "/patients/:patientId/forms/:formContextId/success",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (
      formId: string,
      patientId: string,
      params: {
        title?: string;
        message?: string;
      } = {},
    ) => {
      const base = `${buildFormPath(formId, patientId)}/success`;

      if (params.title) {
        const query = new URLSearchParams(params);
        return `${base}?${query.toString()}`;
      }
      return base;
    },
  },
  formContextPageConfirmDeleteDraftDialog: {
    path: "/patients/:patientId/forms/:formContextId/confirm-delete-draft",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (
      formContextId: string,
      patientId: string,
      params: {
        draftFormId?: string;
      } = {},
    ) => {
      const base = `${buildFormPath(
        formContextId,
        patientId,
      )}/confirm-delete-draft`;
      if (params.draftFormId) {
        const query = new URLSearchParams(params);
        return `${base}?${query.toString()}`;
      }
      return base;
    },
  },
  formContextPageRequestAmendDialog: {
    path: "/patients/:patientId/forms/:formContextId/request-amend",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (
      formContextId: string,
      patientId: string,
      params: {
        formId?: string;
        formTemplateId?: string;
      } = {},
    ) => {
      const base = `${buildFormPath(formContextId, patientId)}/request-amend`;
      if (params.formId || params.formTemplateId) {
        const query = new URLSearchParams(params);
        return `${base}?${query.toString()}`;
      }
      return base;
    },
  },
  formContextPageConfirmFinaliseDialog: {
    path: "/patients/:patientId/forms/:formContextId/confirm-finalise",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (
      formId: string,
      patientId: string,
      params: {
        itemName?: string;
      } = {},
    ) => {
      const base = `${buildFormPath(formId, patientId)}/confirm-finalise`;
      if (params.itemName) {
        const query = new URLSearchParams(params);
        return `${base}?${query.toString()}`;
      }
      return base;
    },
  },
  formContextPageEditMhaStatusDialog: {
    path: "/patients/:patientId/forms/:formContextId/confirm-mha-status-finalise",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (
      formId: string,
      patientId: string,
      params: {
        itemName?: string;
      } = {},
    ) => {
      const base = `${buildFormPath(formId, patientId)}/confirm-mha-status-finalise`;
      if (params.itemName) {
        const query = new URLSearchParams(params);
        return `${base}?${query.toString()}`;
      }
      return base;
    },
  },
  formPageLaunchLinkedForm: {
    path: "/patients/:patientId/forms/:formContextId/launch-linked-form",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (formId: string, patientId: string) =>
      `${buildFormPath(formId, patientId)}/launch-linked-form`,
  },

  formPdfPage: {
    path: "/patients/:patientId/:formId/pdf",
    exact: true,
    component: FormPdfPage,
    breadcrumb: "Form PDF",
    buildFn: (formId: string, patientId: string) =>
      `${buildFormPath(formId, patientId)}/pdf`,
  },
  formDraftsComplete: {
    path: "/patients/:patientId/formdrafts/:formDraftId",
    exact: true,
    component: FormDraftCompletionPage,
    breadcrumb: "Form Drafts",
    buildFn: (formDraftId: string, patientId: string) =>
      `/patients/${patientId}/formdrafts/${formDraftId}`,
  },
  formDraftsSign: {
    path: "/patients/:patientId/formdrafts/:formDraftId/sign",
    exact: true,
    component: FormDraftSignPage,
    breadcrumb: "Sign",
    buildFn: (formDraftId: string, patientId: string) =>
      `/patients/${patientId}/formdrafts/${formDraftId}/sign`,
  },
  formReview: {
    path: "/patients/:patientId/forms/:formContextId/review",
    exact: true,
    component: FormReviewPage,
    breadcrumb: "Review",
    buildFn: (formDraftId: string, patientId: string) =>
      `${buildFormPath(formDraftId, patientId)}/review`,
  },
  notFound: {
    path: "/not-found",
    exact: false,
    component: NotFound,
    breadcrumb: "Not Found",
    buildFn: () => `/not-found`,
  },
  userProfilePage: {
    path: "/profile/:userId",
    exact: true,
    component: UserProfile,
    breadcrumb: "Profile",
    buildFn: (id: string) => `/profile/${id}`,
  },
  userMembershipsPage: {
    path: "/profile/:userId/memberships",
    exact: true,
    component: UserMembershipsPage,
    breadcrumb: "User Memberships",
    buildFn: (id: string) => `/profile/${id}/memberships`,
  },
  adminConverGuestUserPage: {
    path: "/admin/convert-guest/:userId",
    exact: true,
    component: AdminConvertGuestUserPage,
    breadcrumb: "Convert Guest User",
    buildFn: (id: string) => `/admin/convert-guest/${id}`,
  },
  adminApprovedEmailDomains: {
    path: "/admin/approved-email-domains",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/approved-email-domains`,
  },
  adminAuditTrailListsPage: {
    path: "/admin/lists",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/lists`,
  },
  adminAuditTrailCurrentUserPage: {
    path: "/admin/audit/current-user",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/audit/current-user`,
  },
  adminAuditTrailResourcePage: {
    path: "/admin/audit/resource",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: (params: {
      resourceId?: string | null;
      userId?: string | null;
      type?: AuditTrailEntryType[] | null;
      fromDate: Dayjs | null;
      toDate: Dayjs | null;
    }) => {
      const q = createSearchParams({
        ...params,
        fromDate: params.fromDate?.format("YYYY-MM-DD"),
        toDate: params.toDate?.format("YYYY-MM-DD"),
      });

      return `/admin/audit/resource${q.size > 0 ? `?${q.toString()}` : ""}`;
    },
  },
  adminBulkUsersPage: {
    path: "/admin/bulk-configure-users",
    exact: true,
    component: BulkUserConfigurationPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/bulk-configure-users`,
  },
  adminBulkCreateUsersPage: {
    path: "/admin/bulk-create-users",
    exact: true,
    component: BulkUserConfigurationPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/bulk-create-users`,
  },
  managerUsersPage: {
    path: "/manager/users",
    exact: true,
    component: UserOverviewPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/manager/users`,
  },
  managerTeamsPage: {
    path: "/manager/teams",
    exact: true,
    component: TeamsOverviewPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/manager/teams`,
  },
  adminUsersPage: {
    path: "/admin/users",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/users`,
  },
  adminCreateUsersPage: {
    path: "/admin/users/create",
    exact: true,
    component: AdminCreateUserPage,
    breadcrumb: "Create User",
    buildFn: () => `/admin/users/create`,
  },
  adminTeamsPage: {
    path: "/admin/teams",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/teams`,
  },
  adminOrganisationsPage: {
    path: "/admin/organisations",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/organisations`,
  },
  loginPage: {
    path: "/login",
    exact: true,
    component: LoginPage,
    breadcrumb: "Login",
    buildFn: (returnTo?: string) => {
      if (!returnTo) return "/login";
      const newQs = new URLSearchParams();

      newQs.set("returnTo", returnTo);

      return `/login?${newQs.toString()}`;
    },
  },
  mhaStatusDashboardPage: {
    path: "/mha-status-dashboard",
    exact: true,
    component: MhaStatusDashboardPage,
    breadcrumb: "MHA Status Dashboard",
    buildFn: (
      page: number,
      params: Omit<MhaDashboardDataParams, "limit" | "offset">,
    ) => {
      const newQs = new URLSearchParams();

      newQs.set("page", page.toString());

      if (params.statuses) {
        newQs.set("status", params.statuses.join(","));
      }

      if (params.expiryLessThan) {
        newQs.set("expiryLessThan", params.expiryLessThan);
      }

      if (params.organisationName) {
        newQs.set("organisationName", params.organisationName);
      }

      if (params.expiryLessThan) {
        newQs.set("expiryLessThan", params.expiryLessThan);
      }

      return `/mha-status-dashboard?${newQs.toString()}`;
    },
  },
  guestUserLogin: {
    path: "/guest-login",
    exact: true,
    component: GuestUserLoginPage,
    breadcrumb: "Guest Login",

    // Yuck
    buildFn: (userId?: string, returnTo?: string) =>
      returnTo
        ? userId
          ? `/guest-login?userId=${userId}&returnTo=${returnTo}`
          : "/guest-login"
        : "/guest-login",
  },
  patientEditDialog: {
    path: "/patients/:patientId/formdrafts/:formDraftId?patientedit=true",
    exact: true,
    component: FormDraftCompletionPage,
    breadcrumb: "Form Drafts",
    buildFn: (formDraftId: string, patientId: string) =>
      `/patients/${patientId}/formdrafts/${formDraftId}?patientedit=true`,
  },
  formContextAddFileNoteDialog: {
    path: "/patients/:patientId/forms/:formContextId/add-file-note",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (
      formContextId: string,
      patientId: string,
      params: {
        formId?: string;
        formTemplateId?: string;
      } = {},
    ) => {
      const base = `${buildFormPath(formContextId, patientId)}/add-file-note`;
      if (params.formId || params.formTemplateId) {
        const query = new URLSearchParams(params);
        return `${base}?${query.toString()}`;
      }
      return base;
    },
  },
  formContextDeleteFileNoteDialog: {
    path: "/patients/:patientId/forms/:formContextId/delete-file-note",
    exact: true,
    component: FormContextPage,
    breadcrumb: "Form",
    buildFn: (
      formContextId: string,
      patientId: string,
      params: {
        formId?: string;
        formTemplateId?: string;
      } = {},
    ) => {
      const base = `${buildFormPath(
        formContextId,
        patientId,
      )}/delete-file-note`;
      if (params.formId || params.formTemplateId) {
        const query = new URLSearchParams(params);
        return `${base}?${query.toString()}`;
      }
      return base;
    },
  },

  patientEditDialogSuccess: {
    path: "/patients/:patientId/formdrafts/:formDraftId?patienteditsuccess=true",
    exact: true,
    component: FormDraftCompletionPage,
    breadcrumb: "Form Drafts",
    buildFn: (formDraftId: string, patientId: string) =>
      `/patients/${patientId}/formdrafts/${formDraftId}?patienteditsuccess=true`,
  },

  adminRioInstancesPage: {
    path: "/admin/rio-instances",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/rio-instances`,
  },
  rioInstanceCreateEdit: {
    path: "/rio-instances/:rioInstanceId/edit",
    exact: true,
    component: RioInstanceCreateEdit,
    breadcrumb: "Rio Instance Edit",
    buildFn: (rioInstanceId: string) => `/rio-instances/${rioInstanceId}/edit`,
  },
  termsAndConditionsManagementPage: {
    path: "/admin/terms-and-conditions",
    exact: true,
    component: AdminPage,
    breadcrumb: "Thalamos Admin",
    buildFn: () => `/admin/terms-and-conditions`,
  },
  termsAndConditionsCreateEdit: {
    path: "/admin/terms-and-conditions/:termsAndConditionsId/edit",
    exact: true,
    component: TermsAndConditionsCreateEditPage,
    breadcrumb: "Thalamos Admin",
    buildFn: (termsAndConditionsId: string) =>
      `/admin/terms-and-conditions/${termsAndConditionsId}/edit`,
  },
  editMhaStatusPage: {
    path: "/patients/:patientId/mha-status/",
    exact: true,
    component: EditMhaStatusPage,
    breadcrumb: "Edit MHA Status",
    buildFn: (patientId: string) => `/patients/${patientId}/mha-status/`,
  },
};

// These routes have different signatures to the others
export const rawRoutes = {
  ...rawRoutesBase,

  contextSelector: {
    path: "/context-selector",
    exact: true,
    component: ContextSelectorPage,
    breadcrumb: "Select Context",
    buildFn: (returnTo?: string) =>
      returnTo
        ? `/context-selector?returnTo=${encodeURIComponent(returnTo)}`
        : "/context-selector",
  },
};

type RouteKeys = keyof typeof rawRoutes;

export const routeFns = Object.fromEntries(
  Object.entries(rawRoutes).map(([k, v]) => [k as RouteKeys, v.buildFn]),
) as {
  [k in RouteKeys]: (typeof rawRoutes)[k]["buildFn"];
};

export const routes = Object.values(rawRoutesBase).map(
  ({ path, exact, component, breadcrumb }) => ({
    path,
    exact,
    component,
    breadcrumb,
  }),
);
