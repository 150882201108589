import { ExtendedOrganisation } from "@aspire/common";
import { Box } from "@mui/material";
import React from "react";
import { Typeahead } from "~/components/design-system/index.js";
import { api } from "../../api.js";

export function OrganisationPicker<T>({
  error,
  idField,
  nameField,
  values,
  setValues,
  label,
  disabled,
  filterFn,
}: {
  error: string | null;
  idField: keyof T;
  nameField: keyof T;
  values: T;
  setValues: (values: T) => void;
  label: string;
  disabled?: boolean;
  filterFn?: (organisation: ExtendedOrganisation) => boolean;
}) {
  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const organisationName = (values[nameField] as string) ?? "";

  React.useEffect(() => {
    api.organisations.search(organisationName, 10, 0).then((result) => {
      const guestUsersRootOrgId = "85a2bedf-ff18-4671-a0b9-b6a6cfccbc50";
      const independentUsersRootOrgId = "8bded722-14d6-45b2-9eba-415f5e1fd698";
      const filteredResults = result.data.results
        .filter(
          (o) =>
            !["guest", "independent", "nhs-executive", "admin"].includes(
              o.role,
            ) &&
            ![guestUsersRootOrgId, independentUsersRootOrgId].includes(o.id),
        )
        .filter((o) => !filterFn || filterFn(o));

      setOptions(
        filteredResults.map((o) => ({
          label: `${o.name}`,
          value: o.id,
        })),
      );
    });
  }, [organisationName]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typeahead
        disabled={disabled || false}
        inputValue={organisationName as string}
        onInputChange={(e) => {
          const match = options.find((o) => o.label === e);

          if (match) {
            setValues({ ...values, [idField]: match.value, [nameField]: e });
          } else {
            setValues({ ...values, [idField]: null, [nameField]: e });
          }
        }}
        options={options}
        name={"organisation"}
        label={label}
        errorMessage={error || undefined}
      />
    </Box>
  );
}
