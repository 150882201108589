import { isAdminUser } from "@aspire/common";
import { Box, Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { LoggedInUserContext } from "../../Contexts.js";
import { routeFns } from "../../routes.js";
import { DefaultPageProps } from "../defaultProps.js";
import { ApprovedEmailDomainManage } from "./ApprovedEmailDomainManage.js";
import { AuditViewerPage } from "./AuditViewerPage.js";
import { ListsManagementPage } from "./ListsManagementPage.js";
import { OrganisationManagementPage } from "./OrganisationManagementPage.js";
import { RioInstancesManagementPage } from "./RioInstancesManagementPage.js";
import { TeamManagementPage } from "./TeamManagementPage.js";
import { TermsAndConditionsManagementPage } from "./TermsAndConditionsManagementPage.js";
import { UserManagementPage } from "./UserManagementPage.js";

export function AdminPage(props: DefaultPageProps) {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const userContext = React.useContext(LoggedInUserContext);

  useEffect(() => {
    if (!isAdminUser(userContext?.user)) {
      navigate(routeFns.home());
    }
  }, [userContext?.user]);

  const { pathname } = useLocation();
  const { t } = useTranslation();

  React.useEffect(() => {
    switch (pathname) {
      case routeFns.adminUsersPage():
        setTab(0);
        break;
      case routeFns.adminTeamsPage():
        setTab(1);
        break;
      case routeFns.adminOrganisationsPage():
        setTab(2);
        break;
      case routeFns.adminAuditTrailResourcePage({
        resourceId: null,
        type: null,
        userId: null,
        fromDate: null,
        toDate: null,
      }):
        setTab(3);
        break;
      case routeFns.adminApprovedEmailDomains():
        setTab(4);
        break;
      case routeFns.adminAuditTrailListsPage():
        setTab(5);
        break;
      case routeFns.adminRioInstancesPage():
        setTab(6);
        break;
      case routeFns.termsAndConditionsManagementPage():
        setTab(7);
        break;
    }
  }, [pathname]);

  return (
    <Box>
      <Tabs
        value={tab}
        onChange={(ev, value) => {
          if (value === 0) {
            navigate(routeFns.adminUsersPage());
          } else if (value === 1) {
            navigate(routeFns.adminTeamsPage());
          } else if (value === 2) {
            navigate(routeFns.adminOrganisationsPage());
          } else if (value === 4) {
            navigate(routeFns.adminApprovedEmailDomains());
          } else if (value === 3) {
            navigate(
              routeFns.adminAuditTrailResourcePage({
                resourceId: null,
                type: null,
                userId: null,
                fromDate: dayjs().subtract(30, "days"),
                toDate: dayjs(),
              }),
            );
          } else if (value === 5) {
            navigate(routeFns.adminAuditTrailListsPage());
          } else if (value === 6) {
            navigate(routeFns.adminRioInstancesPage());
          } else if (value === 7) {
            navigate(routeFns.termsAndConditionsManagementPage());
          }
        }}
      >
        <Tab label="Users" value={0} />
        <Tab label="Teams" value={1} />
        <Tab label="Organisations" value={2} />
        <Tab label="Lists (Hospitals / Local Authorities)" value={5} />
        <Tab label="Audit Trail Viewer" value={3} />
        <Tab label="Approved Email Domains" value={4} />
        <Tab label="Rio Instances" value={6} />
        <Tab label="Terms & Conditions" value={7} />
      </Tabs>
      <Box>
        {tab === 0 && <UserManagementPage />}
        {tab === 1 && <TeamManagementPage />}
        {tab === 2 && <OrganisationManagementPage />}
        {tab === 3 && <AuditViewerPage />}
        {tab === 4 && <ApprovedEmailDomainManage />}
        {tab === 5 && <ListsManagementPage />}
        {tab === 6 && <RioInstancesManagementPage />}
        {tab === 7 && <TermsAndConditionsManagementPage />}
      </Box>
    </Box>
  );
}
