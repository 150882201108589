import React from "react";
import { Banner, BannerList } from "~/components/design-system/index.js";

import { PatientSearchOutcome } from "@aspire/common";
import { useTranslation } from "react-i18next";

export function PatientSearchResultBanner(
  outcome: PatientSearchOutcome,
  isPatientMerge?: boolean,
) {
  const { t } = useTranslation();
  switch (outcome) {
    case "success/unique-match":
      return isPatientMerge ? (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.uniqueMatch.title",
          )}
          bannerType={BannerList.CONFIRMATION}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.uniqueMatch.body1",
            ) +
            "\n" +
            t(
              "pages.patientSearch.patientSearchResultBanner.uniqueMatch.patientMergeBody2",
            )
          }
        />
      ) : (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.uniqueMatch.title",
          )}
          bannerType={BannerList.CONFIRMATION}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.uniqueMatch.body1",
            ) +
            "\n" +
            t("pages.patientSearch.patientSearchResultBanner.uniqueMatch.body2")
          }
        />
      );

    case "info/multiple-matches":
      return isPatientMerge ? (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.multipleMatches.title",
          )}
          bannerType={BannerList.WARNING}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.multipleMatches.body1",
            ) +
            "\n" +
            t(
              "pages.patientSearch.patientSearchResultBanner.multipleMatches.patientMergeBody2",
            )
          }
        />
      ) : (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.multipleMatches.title",
          )}
          bannerType={BannerList.WARNING}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.multipleMatches.body1",
            ) +
            "\n" +
            t(
              "pages.patientSearch.patientSearchResultBanner.multipleMatches.body2",
            )
          }
        />
      );

    case "error/multiple-pds-matches-and-no-local-match":
      return isPatientMerge ? (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.multiplePdsMatches.title",
          )}
          bannerType={BannerList.ERROR}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.multiplePdsMatches.body1",
            ) +
            "\n" +
            t(
              "pages.patientSearch.patientSearchResultBanner.multiplePdsMatches.patientMergeBody2",
            )
          }
        />
      ) : (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.multiplePdsMatches.title",
          )}
          bannerType={BannerList.ERROR}
          body={
            t(
              "pages.patientSearch.patientSearchResultBanner.multiplePdsMatches.body1",
            ) +
            "\n" +
            t(
              "pages.patientSearch.patientSearchResultBanner.multiplePdsMatches.body2",
            )
          }
        />
      );

    case "error/no-matches":
      return isPatientMerge ? (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.noMatches.title",
          )}
          bannerType={BannerList.ERROR}
          body={t(
            "pages.patientSearch.patientSearchResultBanner.noMatches.patientMergeBody",
          )}
        />
      ) : (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.noMatches.title",
          )}
          bannerType={BannerList.ERROR}
          body={t(
            "pages.patientSearch.patientSearchResultBanner.noMatches.body",
          )}
        />
      );

    case "error/unknown":
      return (
        <Banner
          title={t(
            "pages.patientSearch.patientSearchResultBanner.unknown.title",
          )}
          bannerType={BannerList.ERROR}
          body={t("pages.patientSearch.patientSearchResultBanner.unknown.body")}
        />
      );
  }
}
