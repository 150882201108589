import {
  AuditTrailEntry,
  AuditTrailEntryType,
  isValidUuid,
  UUID,
} from "@aspire/common";
import { css } from "@emotion/css";
import { FolderCopy } from "@mui/icons-material";
import { Box, IconButton, InputLabel, TextField } from "@mui/material";
import json_view_pkg from "@uiw/react-json-view";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DateField,
  Dropdown,
  PopupDialog,
} from "~/components/design-system/index.js";
import { apiHooks } from "../../api.js";
import { LoggedInUserContext } from "../../Contexts.js";
import { routeFns } from "../../routes.js";
import { DefaultPageProps } from "../defaultProps.js";
const JsonView = json_view_pkg.default;

type SearchResultsType = {
  loading: boolean;
  data: {
    entries: AuditTrailEntry[];
    count: number;
  };
};

export function JsonModal({
  data,
  open,
  onClose,
}: {
  data: any;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <PopupDialog open={open} onClose={onClose}>
      {data ? (
        <JsonView
          value={data}
          displayObjectSize={false}
          displayDataTypes={false}
          shortenTextAfterLength={100}
        />
      ) : (
        <></>
      )}
    </PopupDialog>
  );
}

export function AuditViewerPageInner({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  page,
  setPage,
  userId,
  setUserId,
  type,
  setType,
  numPages,
  resourceId,
  setResourceId,
  searchResult,
}: {
  fromDate: Dayjs;
  setFromDate: (params: any) => void;
  toDate: Dayjs;
  setToDate: (params: any) => void;
  page: number;
  setPage: (params: any) => void;
  numPages: number;
  resourceId: string | null;
  setResourceId: (params: string | null) => void;
  userId: string | null;
  setUserId: (params: string | null) => void;
  type: AuditTrailEntryType[] | null;
  setType: (params: AuditTrailEntryType[] | null) => void;
  searchResult: SearchResultsType;
}) {
  const typesMap = [...Object.values(AuditTrailEntryType)]
    .sort()
    .map((type) => ({ value: type, label: type }));

  const [modalData, setModalData] = useState<AuditTrailEntry | null>(null);

  return (
    <Box>
      <Box sx={{ width: "100%", marginTop: "2em", display: "flex" }}>
        <Box sx={{ marginRight: "1em" }}>
          <DateField
            name={"from"}
            label={"From Date (*)"}
            value={fromDate}
            maxDate={dayjs()}
            onChange={(e) => e && setFromDate(e)}
          ></DateField>
        </Box>
        <Box>
          <DateField
            name={"from"}
            label={"To Date (*)"}
            value={toDate}
            maxDate={dayjs()}
            onChange={(e) => e && setToDate(e)}
          ></DateField>
        </Box>

        <Box sx={{ marginLeft: "1em", minWidth: 200 }}>
          <Dropdown
            label={"Entry Type"}
            name={"type"}
            values={typesMap}
            selectedValue={type}
            onChange={(value) => setType(value)}
            multiple={true}
          />
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", marginBottom: "1em" }}>
        <Box sx={{}}>
          <InputLabel
            sx={{ marginBottom: "0.5em" }}
            id="demo-simple-select-label"
            error={resourceId ? !isValidUuid(resourceId) : false}
          >
            <strong
              css={css`
                padding-bottom: 1em;
              `}
            >
              Resource ID (UUID)
            </strong>
          </InputLabel>
          <TextField
            sx={{ width: "500px" }}
            value={resourceId}
            error={resourceId ? !isValidUuid(resourceId) : false}
            onChange={(e) => setResourceId(e.target.value)}
          />
        </Box>
        <Box sx={{ marginLeft: "1em" }}>
          <InputLabel
            sx={{ marginBottom: "0.5em" }}
            id="demo-simple-select-label"
            error={userId ? !isValidUuid(userId) : false}
          >
            <strong
              css={css`
                padding-bottom: 1em;
              `}
            >
              User ID (UUID)
            </strong>
          </InputLabel>
          <TextField
            sx={{ width: "500px" }}
            value={userId}
            error={userId ? !isValidUuid(userId) : false}
            onChange={(e) => setUserId(e.target.value)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {!searchResult.loading && (searchResult.data?.count || 0) > 0 && (
          <>
            <JsonModal
              data={modalData}
              open={!!modalData}
              onClose={() => setModalData(null)}
            />
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <button onClick={() => page > 0 && setPage(page - 1)}>
                &#60;
              </button>
              <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
                Page {page + 1} of {numPages}
              </Box>
              <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
                &#62;
              </button>
            </Box>
            <table cellSpacing={20}>
              <tr>
                <th></th>
                <th>Event Type</th>
                <th>Resource Names</th>
                <th>Timestamp</th>
                <th>User Name</th>
                <th>Team Name</th>
                <th>Org Name</th>
              </tr>
              {searchResult.data?.entries.map((entry) => (
                <tr>
                  <td>
                    <IconButton onClick={() => setModalData(entry)}>
                      <FolderCopy />
                    </IconButton>
                  </td>
                  <td>{entry.type}</td>
                  <td>
                    {entry.resources
                      .filter((n) => n.resourceName)

                      .map((r) => `${r.resourceType}: ${r.resourceName}`)

                      .map((s) => (
                        <>
                          {s}
                          <br />
                        </>
                      ))}
                  </td>
                  <td>
                    {dayjs(entry.timestamp).format("HH:mm:ss DD/MM/YYYY")}
                  </td>
                  <td>{entry.userName}</td>
                  <td>{entry.teamName}</td>
                  <td>{entry.organisationName}</td>
                </tr>
              ))}
            </table>
          </>
        )}
      </Box>
    </Box>
  );
}

export function AuditViewerPage(props: DefaultPageProps) {
  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(1, "days"));
  const [toDate, setToDate] = useState<Dayjs>(dayjs());
  const [page, setPage] = useState(0);

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const qs = new URLSearchParams(search);
  const resourceIdFromUrl = qs.get("resourceId");
  const userIdFromUrl = qs.get("userId");
  const typesFromUrl = qs.getAll("type");
  const fromDateFromUrl = qs.get("fromDate");
  const toDateFromUrl = qs.get("toDate");

  const [resourceId, setResourceId] = useState<string | null>(null);
  const [userId, setUserId] = useState<UUID | null>(null);
  const [type, setType] = useState<AuditTrailEntryType[] | []>([]);

  React.useEffect(() => {
    setResourceId(resourceIdFromUrl || null);
    setUserId(userIdFromUrl || null);
    setType((typesFromUrl as AuditTrailEntryType[]) || []);
    setFromDate(
      fromDateFromUrl && dayjs(fromDateFromUrl).isValid()
        ? dayjs(fromDateFromUrl)
        : dayjs().subtract(30, "days"),
    );
    setToDate(
      toDateFromUrl && dayjs(toDateFromUrl).isValid()
        ? dayjs(toDateFromUrl)
        : dayjs(),
    );
  }, [
    resourceIdFromUrl,
    userIdFromUrl,
    typesFromUrl.join(","),
    fromDateFromUrl,
    toDateFromUrl,
  ]);

  const userContext = React.useContext(LoggedInUserContext);

  const limit = 200;

  const [searchResult, refetchResource] = apiHooks.audit.search({
    fromDate: fromDate.format("YYYY-MM-DD"),
    toDate: toDate.format("YYYY-MM-DD"),
    limit: limit,
    offset: page * limit,
    userId: userId && isValidUuid(userId) ? userId : null,
    resourceId: resourceId && isValidUuid(resourceId) ? resourceId : null,
    type,
  });

  useEffect(() => {
    refetchResource();
  }, [fromDate, toDate, page, type, userId, resourceId]);

  useEffect(() => {
    setPage(0);
  }, [fromDate, toDate]);

  const numPages = searchResult.data
    ? Math.ceil(searchResult.data.count / limit)
    : 1;

  const changeParams = (
    p: Partial<{
      resourceId: string | null;
      userId: string | null;
      type: AuditTrailEntryType[] | null;
      fromDate: Dayjs;
      toDate: Dayjs;
    }>,
  ) =>
    navigate(
      routeFns.adminAuditTrailResourcePage({
        fromDate,
        toDate,
        type,
        userId,
        resourceId,
        ...p,
      }),
    );

  return (
    <AuditViewerPageInner
      fromDate={fromDate}
      setFromDate={(newFromDate: Dayjs) => {
        if (newFromDate.isValid()) {
          setFromDate(newFromDate);
          changeParams({
            fromDate: newFromDate,
          });
        }
      }}
      toDate={toDate}
      setToDate={(newToDate: Dayjs) => {
        if (newToDate.isValid()) {
          setToDate(newToDate);
          changeParams({
            toDate: newToDate,
          });
        }
      }}
      page={page}
      setPage={setPage}
      numPages={numPages}
      userId={userId}
      setUserId={(newUserId) => {
        setUserId(newUserId);
        changeParams({
          userId: newUserId,
        });
      }}
      type={type}
      setType={(newType) => {
        const filteredTypes = (newType || []).filter((x) => x);
        setType(filteredTypes);
        changeParams({ type: filteredTypes });
      }}
      resourceId={resourceId}
      setResourceId={(newResourceId) => {
        setResourceId(newResourceId);
        changeParams({ resourceId: newResourceId });
      }}
      searchResult={searchResult as SearchResultsType}
    />
  );
}
